//Reset import
@import url(./_reset.scss);

//Variables import
@import url(./utils/_variables.scss);

//Typography import
@import url(./_typography.scss);

//Mixins import
@import url(./utils/_mixins.scss);

//Button import
@import url(./components/_buttons.scss);

//Card import
@import url(./components/_card.scss);

//footer import
@import url(./layout/footer/footer.scss);

//header import
@import url(./layout/header/header.scss);

//Home Page import
@import url(./homePage/homePage.scss);

//ThreeD World import
@import url(./homePage/threedWorld/threedWorld.scss);

//Responsive import
@import url(./components/responsive/_responsive.scss);

input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid white;
    background-color: transparent;
    color: white;
  }
  
  :focus-visible {
    outline: none !important;
  }

  textarea {
    padding: 1vh;
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
  }
 