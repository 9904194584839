
  
.three_icon_corporate {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
}

  
.three_icon_corporate-two {
  position: absolute;
  top: 18%;
  right: 0%;
  transform: translate(-5%, -50%);

}

  
  .page-alibaba .card-title {
    font-size: 4em;
  }
  

  .bg-alibaba {
    background-image: url(../../assets/gallery/background_img/alibaba_bg.png);
    background-size: cover;
  }

  .alibaba-logo-div{
    margin-top: 8vh;
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
    padding: 10vh 0px 10vh 0px;
  }

  .alibaba-logo-div img{
    width: 60%;
    height: auto;
  }

  .bg-1f1f1f{
    background-color: #1f1f1f;
  }
  .alibaba-inner-top{
    margin-top: 25vh;
  }
  @media (max-width: 767px) {
   .scroll-mobile-alibaba{
    overflow: scroll;
   }
  }
  