@import "../utils/mixins.scss";
@import "../utils/variables.scss";


.card {
    background-color: transparent;
}

.card-blur {
    @include bg-blur;
    border-radius: 1em;
  border: 1px solid #969696;
}

.cardSlider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    width: 100%;
    @include border-radius($border-radius-large);
    color: $text-color;
    height: 100%;
}

.card-title {
    color: $text-color;
}

/* cardswiper start*/
.swiper-card-main .title-main .btn-crewier {
    transform: translateY(0px);
    transition: 0.5s;
    opacity: 0;
    height: auto;
    position: absolute;
    top: 90%;
  }
  
  .swiper-card-main .title-main:hover .btn-crewier {
    transform: translateY(-30px);
    opacity: 1;
    height: auto;
  }
  .swiper-card-main .title-main:hover .content {
    transform: translateY(-20px);
  }
  /* cardswiper end*/

  .card-slider {
    position: absolute;
    top: 70%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

  }
  .card-inner-overflow {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .transition-bg-main{
    height: 5vh;
    background-color:black ;
    display: none;
  }
  .transition-bg{
    height: 5vh;
    border-radius: 8vh 8vh 0 0;
    background-color: #191919;
  }
  @media screen and (max-width: 767px) {
    .transition-bg-main{
      display: block;
    }
    .swiper-card-main .title-main .btn-crewier {
   
      opacity: 1;
   
      top: 85%;
    }
    .swiper-card-main .title-main:hover .btn-crewier {
      transform: none;
    
    }
    .swiper-card-main .title-main:hover .content {
      transform: none;
    }
  }