.banner_text {
  margin-top: 6vh;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; /* Tam genişlik */
  box-sizing: border-box;
  padding: 5vh 0px 5vh 0px; /* Sağ ve sol kenarlardan boşluk */
  background-color: #1f1f1f;
}

.scrolling-text {
  display: flex;
  justify-content: center;
  font-size: 90px; /* Daha büyük yazı boyutu */
  font-weight: bold;
  line-height: 1; /* Satır yüksekliği */
  white-space: nowrap;
}

.scrolling-text .outline {
  display: inline-block;
  padding: 0 10px;
  color: #1f1f1f; /* Garip çizgilerin önüne geçmek için geçici arka plan rengi */
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white; /* Dış çizgi efekti */
}

.scrolling-text .filled {
  display: inline-block;
  padding: 0 10px;
  color: white;
}
