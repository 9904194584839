.accordion-flush>.accordion-item>.accordion-header .accordion-button, .accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed{

    background-color: #1f1f1f !important;

    color: white !important;
    border-radius: 10px !important;
  
}
.accordion-item {

    background-color: #1f1f1f !important;

}
.accordion-flush>.accordion-item{
    border-radius: 10px !important;
    border: 1px solid white !important;
    margin-bottom: 2vh;
}
.accordion {
    --bs-accordion-border-color:transparent !important
}
.accordion-body{
    color: white ;
    font-size: 0.9rem;
}
.accordion-button::after{
    background-image: url("../../assets/icon/plus.png") !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/icon/minus.png") !important;

}
.accordion-button:not(.collapsed){
    box-shadow: none !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
@media screen and (max-width: 767px) {
    .accordion-button::after{
        display: none;
    }
  }