.custom-perf {
    font-size: 12px; /* Boyutu küçültmek için font-size ayarı */
    background-color: rgba(0, 0, 0, 0.5); /* Arka plan rengini ayarla */
    color: #fff; /* Yazı rengini ayarla */
  }
  
  div.three_d_world {
    background-color: #01040c;
  }
  
  div.three_d_world{
    height: 100vh !important;
  }
  