.page-about-us-one {
  min-height: 30rem;
}

.page-about-us .three_icon_corporate {
  position: absolute;
  top: 30%;
  right: 0;
  transform: translate(-0%, -50%);
 width: 50%;
  
}

.page-about-us .card-title {
  font-size: 4em;
}


.bg-about-us {
  background-image: url(../../assets/gallery/background_img/hakkimizda_bg.png);
  background-size: cover;
}
.page-about-us .banner_text{
  background-color: transparent;
}