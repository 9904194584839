.desktop-world{
    display: block;
}
.mobile-world{
    display: none;
}
.bg-home{
  
        background-image: url(../../assets/gallery/background_img/home_bg.png);
        background-size: cover;
  
}
@media screen and (max-width: 767px) {
    .bg-home{
        background-image: url(../../assets/gallery/background_img/home_bg1.png);

        background-size:auto  
}
  }
  