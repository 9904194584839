// Import variables
@import './utils/variables.scss';
@import './utils/mixins.scss';

// Import Montserrat fonts
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

// Base Typography
body {
  font-family: $font-primary;
  font-size: $font-size-base; // 1em = 16px
  color: $text-color;
  background-color: #1f1f1f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  margin: 0 0 12px 0; // 16px
  color: $text-color;
}

h1 {
  font-weight: bold;
  color: $text-color;
}

h2 {
  font-weight: normal;
  color: $text-color;
}

h3 {
  font-weight: normal;
  color: $text-color;
}

h4 {
  font-weight: normal;
  color: $text-color;
}

h5 {
  font-weight: normal;
  color: $text-color;

}

h6 {
  font-weight: normal;
  color: $text-color;

}

p {
  margin: 0 0 $spacing-medium 0; // 16px
  color: $text-color;
}

a {
  color: white;
  text-decoration: none;
 
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

// Lists
ul,
ol {
  margin: 0 0 $spacing-medium 0; // 16px
  padding: 0 0 0 $spacing-medium; // 16px

  li {
    margin: 0 0 $spacing-small 0; // 4px
  }
}

// Blockquotes
blockquote {
  margin: 0 0 $spacing-medium 0; // 16px
  padding: $spacing-medium; // 16px
  background-color: lighten($background-color, 10%);
  border-left: 4px solid $primary-color;
  color: darken($text-color, 10%);
  font-style: italic;

  p {
    margin: 0;
  }
}

// Code
code {
  font-family: 'Courier New', Courier, monospace;
  background-color: lighten($background-color, 5%);
  padding: 0 $spacing-small; // 4px
  border-radius: $border-radius-small; // 4px
}

pre {
  font-family: 'Courier New', Courier, monospace;
  background-color: lighten($background-color, 5%);
  padding: $spacing-medium; // 16px
  border-radius: $border-radius-small; // 4px
  overflow-x: auto;

  code {
    background-color: transparent;
    padding: 0;
  }
}

