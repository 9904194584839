// İçe aktarmalar
@import '../utils/variables'; // Doğru dosya yolunu belirtin
@import '../utils/mixins'; // Mixins dosyasının yolunu belirtin

// Buton stilleri
.btn-crewier {
  @include border($border-width, $border-style, $text-color); // Border mixin'i kullanımı
  background-color: transparent;
  width: max-content;
  overflow: hidden;
  color: $text-color;
  padding: 0.5vh 2.5vh;
  cursor: pointer;
  border-radius: 9px;
  @include transition(background-position, 0.3s, ease); // Transition mixin'i kullanımı
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    transparent 50%,
    $text-color 50%
  );
  @include flex-center; 
}

// Hover durumu
.btn-crewier:hover {
  background-position: -100% 0;
  color: $dark-color;
  background-color: var(--color-three);
}
