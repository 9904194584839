/* faq start */
.faq-page .title-main:hover .content {
    transform: translateY(-25px);
  }
  .faq-page .title-main .answer-main {
    transform: translateY(0px);
    transition: 0.5s;
    opacity: 0;
   height: 1vh;
   overflow: hidden;
  }
  
  .faq-page .title-main:hover .answer-main {
    transform: translateY(-10px);
    opacity: 1;
  height: auto;
  overflow: hidden;
  }
  .faq-page .cardSlider {
    height: 64vh;
  }

  /* faq end*/