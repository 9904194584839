.swiper-card-main-two {
  height: 100%;
}
.swiper-card-main-two .swiper {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.swiper-card-main-two .swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-card-main-two .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay-text {
  position: absolute;
  top: 30px;
  left: 30px;

  padding: 5px 10px;
}
.overlay-text h3 {
  text-align: start;
  color: black;
}
.overlay-text-end {
  position: absolute;
  bottom: 30px;
  right: 30px;

  padding: 5px 10px;
}
.overlay-text-end h3 {
  text-align: start;
  color: white;
}

.buse .cardSlider {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.buse .card-body {
  overflow-x: auto;
}
.buse-main {
  overflow: scroll;
}
.bg-other {
  background: none;
}
.buse .card-body img {
  max-width: 100%;
  height: auto;
}

.buse ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.footer-bottom-div {
  border-bottom: 1px solid white;
}
::placeholder {
  color: white;
}

@media (max-width: 767px) {
  .px-sm-0-c{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .swiper-card-main-two {
    height: 60vh;
    padding: 0 !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .overlay-text {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 5px 10px;
    background: #8080802b;
    border-radius: 10px;
    border: 1px solid white;
  }
  .overlay-text-end {
    position: absolute;
    left: 20px;
    padding: 5px 10px;
    background: #8080802b;
    border-radius: 10px;
    border: 1px solid white;
  }
  .overlay-text h3 {
    text-align: start;
    color: black;
    padding: 1vh;
    margin: 0;
  }
  .overlay-text-end h3 {
    text-align: start;
    padding: 1vh;
    margin: 0;
  }
  .bg-other {
    background: #383838;
  }
}


