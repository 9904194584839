@import "../../utils/mixins.scss";

/*---
	3. Footer
---*/
.footer-crewier{
  position: relative;
  z-index: 2;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: none;
  border-top: 1px solid white;  border-top:1px solid white
}

.footer--fixed .osi-footer,
.footer--sticky .osi-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer--sticky .osi-footer {
  z-index: 3;
}

.osi-footer.footer--dark {
  background: #000;
}

.osi-footer.footer--white {
  background: #FAFAFA;
}

.osi-footer .footer--default {
  padding: 90px 0 30px 0;
}

 .copyright{
  font-size: 18px;
  color: #FF6600;

}

.osi-footer .separator {
  margin: 60px 0 30px 0;
  border-top: 7px solid transparent;
  background-image: url("../../../../assets/gallery/footer/fline.svg");
}

ul.osi-footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.osi-footer-menu li {
  margin: 0 0 14px 0;
  font-size: 18px;
}

.osi-footer-lnk {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
}
.copyright:hover{
  color: gray;
}
@media (max-width: 1024px) {
  .footer--fixed .wrapper {
    padding-bottom: 80px;
    margin-bottom: 0 !important;
  }
  .footer--fixed .osi-footer, .footer--sticky .osi-footer {
    position: relative;
  }
}
@media (max-width: 767px) {
  .osi-footer .copyright {
    font-size: 16px;
  }
  .osi-footer ul.osi-footer-menu li {
    font-size: 16px;
  }
  .osi-footer .footer--default {
    padding: 60px 0 30px 0;
  }
  .osi-footer-lnk {
    font-size: 24px;
  }
}