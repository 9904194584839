.canvas-container {
  position: relative;
  width: 100%;
}
.leva-c-kWgxhW-czBHep-hideTitleBar-false {
  display: none !important;
}

.content {
  transition: 0.5s;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet-active {
  background: white !important;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  border: 1px solid white !important;
}
.swiper-pagination-bullet {
  width: 1rem !important;
  height: 1rem !important;
  border: 1px solid white;
}
.swiper-button-prev, .swiper-button-next{
color: #FF591C !important;
font-weight: 900;
}