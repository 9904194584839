// Colors
$primary-color: #FF591C;
$secondary-color: #00C0C7;
$accent-color: #FF6600;
$dark-color: #1F1F1F;
$background-color: #6C6C6C;
$text-color: #F8F8F8;
$border-primary-color: #969696;

// Font stacks
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Montserrat', sans-serif;

// Font sizes
$font-size-base: 1em; // 16px
$font-size-large: 1.5em; // 24px
$font-size-small: 0.75em; // 12px

// Spacing
$spacing-unit: 0.5em; // 8px
$spacing-small: $spacing-unit * 0.5; // 4px
$spacing-medium: $spacing-unit * 2; // 16px
$spacing-large: $spacing-unit * 1.5; // 12px

// Border radius
$border-radius-small: 0.25em; // 4px
$border-radius-medium: 0.5em; // 8px
$border-radius-large: 1em; // 16px

// Shadows
$shadow-small: 0 0.0625em 0.1875em rgba(0, 0, 0, 0.12), 0 0.0625em 0.125em rgba(0, 0, 0, 0.24);
$shadow-medium: 0 0.1875em 0.375em rgba(0, 0, 0, 0.16), 0 0.1875em 0.375em rgba(0, 0, 0, 0.23);
$shadow-large: 0 0.625em 1.25em rgba(0, 0, 0, 0.19), 0 0.375em 0.375em rgba(0, 0, 0, 0.23);

// Breakpoints
$breakpoint-small: 37.5em; // 600px
$breakpoint-medium: 56.25em; // 900px
$breakpoint-large: 75em; // 1200px

// Z-index
$z-index-modal: 62.5em; // 1000
$z-index-dropdown: 56.25em; // 900
$z-index-header: 50em; // 800
$z-index-footer: 43.75em; // 700

// Border 
$border-width: 1px;
$border-style: solid;
$border-color: $border-primary-color;