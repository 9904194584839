/* blog start*/
.swiper-card-blog .swiper-slide .swiper-blog-img {
    overflow: hidden;
    border-radius: 10px;
  }
  
  .swiper-card-blog .swiper-slide:hover .swiper-blog-img img {
    transform: scale(1.5);
    transition: transform 0.5s ease;
  }
  /* blog end*/
  .swiper-card-blog  a{
    color: white !important;
    text-decoration: none !important;
  }
  /* Hover etkisini ve animasyonları telefon ekranlarında devre dışı bırakmak için media query */
@media (max-width: 769px) {
  .swiper-card-blog .swiper-slide:hover .swiper-blog-img img {
      transform:none
  }
}
