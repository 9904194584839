/* responsive */
@media screen and (max-width: 376px) {
  .cardSlider {
    margin: 0 !important;
    height: 100%;
  }
  .img-export-commerce {
    width: inherit;
  }
  .osi-menu-container {
    top: 87px;
  }

  .canvas-container div.three_d_world {
    height: 116vh !important;
  }
  .blog-page .cardSlider {
    overflow: hidden;
  }
  .blog-page .card-body {
    height: 100%;
  }
  .scrolling-text h1 {
    font-size: 1.5rem;
  }
  .page-about-us .card-title {
    font-size: 2rem;
  }

  .page-about-us .three_icon_corporate {
    width: 32%;
  }
  .page-alibaba .three_icon_corporate {
    position: inherit;
    transform: none;
  }
  .three_icon_corporate-two {
    position: inherit;
    transform: none;
    width: 70%;
  }
  .alibaba-inner-top {
    margin-top: 2vh;
  }
  .container.osimenucontainer {
    margin-left: 0;
  }
  .parallax {
    background-position: center;
  }
  a.osi-menu-btn,
  .osi-menu-btn {
    width: 40px;
  }
  .desktop-world {
    display: none;
  }
  .mobile-world {
    display: block;
  }
  .card-slider {
    top: 68%;
  }

  .scrolling-text {
    font-size: 33px;
  }
  .page-about-us .three_icon_corporate {
    top: 10%;
    right: 0;
    transform: translate(50%, -50%);
  }
}
@media screen and (min-width: 377px) and (max-width: 767px) {
  .cardSlider {
    margin: 0 !important;
    height: 100%;
  }
  .osi-menu-container {
    top: 87px;
  }

  .alibaba-inner-top {
    margin-top: 2vh;
  }
  .three_icon_corporate-two {
    position: inherit;
    transform: none;
    width: 70%;
  }
  .img-export-commerce {
    width: inherit;
  }
  .canvas-container div.three_d_world {
    height: 116vh !important;
  }
  .blog-page .cardSlider {
    overflow: hidden;
  }
  .blog-page .card-body {
    height: 100%;
  }
  .scrolling-text h1 {
    font-size: 1.5rem;
  }
  .page-about-us .card-title {
    font-size: 2rem;
  }

  .page-about-us .three_icon_corporate {
    width: 32%;
  }
  .page-alibaba .three_icon_corporate {
    position: inherit;
    transform: none;
  }
  .container.osimenucontainer {
    margin-left: 0;
  }
  .parallax {
    background-position: center;
  }
  a.osi-menu-btn,
  .osi-menu-btn {
    width: 40px;
  }
  .desktop-world {
    display: none;
  }
  .mobile-world {
    display: block;
  }
  .card-slider {
    top: 68%;
  }
  .scrolling-text {
    font-size: 33px;
  }
  .page-about-us .three_icon_corporate {
    top: 10%;
    right: 0;
    transform: translate(50%, -50%);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .cardSlider {
    margin: 0 !important;
    height: 100%;
  }
  .transition-bg-main {
    display: block;
  }
  .services-img-five {
    width: fit-content;
  }
  .px-sm-0-c {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .img-export-commerce {
    width: -webkit-fill-available;
  }
  .canvas-container div.three_d_world {
    height: 100vh !important;
  }
  .card-slider {
    top: 75%;
  }
  .blog-page .card-body {
    height: 100%;
  }
  .page-alibaba .three_icon_corporate {
    position: inherit;
    transform: none;
  }
  .three_icon_corporate-two {
    position: inherit;
    transform: none;
    width: 60%;
  }
  .alibaba-inner-top {
    margin-top: 2vh;
  }
  .swiper-card-main-two {
    padding: 0 !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .page-about-us .three_icon_corporate {
    top: 10%;
    right: 0;
    transform: translate(-0%, -50%);
  }

  .parallax {
    background-position: center;
  }
  .desktop-world {
    display: none;
  }
  .mobile-world {
    display: block;
  }
  .bg-home {
    background-image: url(../../../gallery/background_img/home_bg1.png);
    background-size: auto;
  }

  .services-img {
    width: min-content;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .services-img {
    width: auto;
  }
}
