@import "../../utils/variables";
@import "../../utils/mixins";


/*---
	2. Header
---*/
.osi-header {
  padding: 10px 0;
  position: absolute;
  top: 0;
  background-color: black;
  left: 0;
  width: 100%;
  z-index: 19;
  color: #000;
  background-color: #000;
}

.osi-header-default {
  padding: 20px 0;
}

.admin-bar .osi-header {
  top: 32px;
}

.osi-header.sticky {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.header--white.osi-header.sticky {
  background-color: #000;
}

.osi-header .row>div:first-child {
  z-index: 2;
}

.slideUp {
  position: fixed;
  top: -140px;
  left: 0;
  width: 100%;
}

.slideDown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
}

.slideUp,
.slideDown {
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.osi-logo {
  padding: 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.osi-logo:hover {
  text-decoration: none;
}

.logo--large {
  display: block;
  font-size: 26px;
  line-height: 40px;
  font-weight: 600;
}

.logo--small {
  display: block;
  font-size: 11px;
  line-height: 1.18;
  opacity: 0.6;
}

.header--white .logo--small,
.header--white .logo--large {
  color: #fff;
}

.osi-logo-image {
  position: relative;
  line-height: 1;
}

.osi-logo-image img {
  max-width: 100%;
  height: auto;
}

.osi-logo-image img.logo--white,
.header--white .osi-logo-image img,
.header--active .osi-logo-image img {
  display: none;
}

.header--white .osi-logo-image img.logo--white,
.header--active .osi-logo-image img.logo--white {
  display: block;
}

a.osi-head-btn,
.osi-head-btn {
  position: relative;
  float: right;
  height: 40px;
}

.osi-head-btn::before,
.osi-head-btn::after {
  display: none;
}

.header--white a.osi-head-btn,
.header--white .osi-head-btn,
.header--active a.osi-head-btn,
.header--active .osi-head-btn {
  color: #fff;
}

.header--active a.osi-head-btn,
.header--active .osi-head-btn {
  transition-delay: 0.6s;
}

.header--builder a.osi-head-btn,
.header--builder .osi-head-btn {
  display: inline-block;
  float: none;
}

.header--white .osi-head-btn .arrow::before,
.header--active .osi-head-btn .arrow::before {
  background: #fff;
}

.header--active .osi-head-btn .arrow::before {
  transition-delay: 0.6s;
}

.osi-head-btn .arrow span::before,
.osi-head-btn .arrow span::after {
  background: #fff;
}

.header--white .osi-head-btn .arrow span::before,
.header--white .osi-head-btn .arrow span::after,
.header--active .osi-head-btn .arrow span::before,
.header--active .osi-head-btn .arrow span::after {
  background: #000;
}

.osi-head-btn:hover .arrow span::before,
.osi-head-btn:hover .arrow span::after {
  background: #000;
}

.header--active .osi-head-btn .arrow span::before,
.header--active .osi-head-btn .arrow span::after {
  transition-delay: 0.6s;
}

.osi-menu-horizontal {
  display: none;
}

.osi-menu-horizontal ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

// @media (max-width: 1024px) {
//   .osi-menu-btn {
//     display: inline-block !important;
//   }
// }

@media (min-width: 1025px) {
  .osi-menu-horizontal {
    display: block;
  }

  .osi-menu-horizontal+.osi-menu-btn {
    display: none;
  }
}

.osi-menu-horizontal ul li {
  padding: 0 15px;
  display: inline-block;
  vertical-align: top;
  line-height: 40px;
  position: relative;
}

.header--white .osi-menu-horizontal ul li,
.header--white .osi-menu-horizontal ul li a {
  color: #fff;
}

.osi-menu-horizontal ul li a {
  font-weight: 500;
}

.osi-menu-horizontal ul li i {
  margin-left: 8px;
  font-size: 0.8em;
  transition: all 0.3s ease 0s;
}

.osi-menu-horizontal ul li ul li:hover i {
  transform: rotate(-90deg);
}

.osi-menu-horizontal ul ul {
  margin-left: -120px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 0;
  width: 240px;
  background: #fff;
  transform: translate(0, 30px);
  -webkit-transform: translate(0, 30px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu-horizontal ul ul ul {
  left: 100%;
  margin-left: 0;
}

.osi-menu-horizontal ul li:hover>ul {
  transform: translate(0, 40px);
  -webkit-transform: translate(0, 40px);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.osi-menu-horizontal ul li:hover>ul ul {
  transform: translate(0, 0);
  -webkit-transform: translate(0, -35px);
}

.osi-menu-horizontal ul ul:before {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border: 1px solid #000;
}

.osi-menu-horizontal ul ul ul:before {
  left: 0;
}

.osi-menu-horizontal ul ul li {
  margin: 15px 0;
  padding: 0;
  display: block;
  line-height: normal;
}

.osi-menu-horizontal ul ul li,
.header--white .osi-menu-horizontal ul ul li,
.osi-menu-horizontal ul ul li a,
.header--white .osi-menu-horizontal ul ul li a {
  color: #000;
}

.osi-menu-horizontal ul ul li a {
  font-weight: 400;
  font-size: 16px;
}

.osi-header-default .osi-menu-horizontal ul ul li {
  text-align: center;
}

a.osi-menu-btn,
.osi-menu-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 40px;
  align-items: center;
  z-index: 2;
}

.osi-menu-btn span {
  margin-top: -6px;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 12px;
  display: block;
}

.osi-menu-btn span::before,
.osi-menu-btn span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: #000;
  transform: rotate(0);
  -webkit-transform: rotate(0);
  transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
}

.header--white .osi-menu-btn span::before,
.header--white .osi-menu-btn span::after,
.header--active .osi-menu-btn span::before,
.header--active .osi-menu-btn span::after {
  background: #fff;
}

.header--active .osi-menu-btn span::before,
.header--active .osi-menu-btn span::after {
  transition-delay: 0.6s;
}

.osi-menu-btn span::after {
  top: auto;
  bottom: 0;
}

.osi-menu-btn.btn--active span::before {
  top: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.osi-menu-btn.btn--active span::after {
  bottom: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.osi-menu-popup {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.osi-menu-popup.menu--visible {
  opacity: 1;
  visibility: visible;
  z-index: 0;
}

.osi-menu-overlay,
.osi-menu-overlay-after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  //background: $accent-color;
  transform-origin: top center;
  animation: osi-menu-overlay-close 0.9s forwards cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-animation: osi-menu-overlay-close 0.9s forwards cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu-overlay.overlay--circle,
.osi-menu-overlay-after.overlay--circle {
  left: -25vw;
  top: -25vw;
  width: 150vw;
  height: 150vw;
  border-radius: 100%;
  transform: scale(0);
  -webkit-transform: scale(0);
}

.osi-menu-overlay-after {
  // background: #000;
  position: relative;
    z-index: 2;
    padding: 20px;
    background: rgba(12, 12, 12, 0.713); /* Şeffaf beyaz arka plan */
    -webkit-backdrop-filter: blur(30px); /* Safari ve diğer WebKit tarayıcıları için */
     backdrop-filter: blur(30px); /* Diğer modern tarayıcılar için */
    border-radius: 10px;
    box-shadow: none;
  animation: osi-menu-overlay-close 0.6s forwards cubic-bezier(0.3, 0, 0.3, 1);
}




.osi-menu-overlay-after.overlay--circle {
  animation: osi-menu-overlay-close-circle 1.2s forwards cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu-popup.menu--open .osi-menu-overlay {
  animation: osi-menu-overlay-open 0.6s forwards cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu-popup.menu--open .osi-menu-overlay-after {
  animation: osi-menu-overlay-open 0.6s forwards cubic-bezier(0.3, 0, 0.3, 1) 0.3s;
}

.osi-menu-popup.menu--open .osi-menu-overlay.overlay--circle {
  animation: osi-menu-overlay-open-circle 1.2s forwards cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu-popup.menu--open .osi-menu-overlay-after.overlay--circle {
  animation: osi-menu-overlay-open-circle 1.2s forwards cubic-bezier(0.3, 0, 0.3, 1) 0.3s;
}

@keyframes osi-menu-overlay-close {
  0% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  50% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  100% {
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
  }
}

@keyframes osi-menu-overlay-open {
  0% {
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@keyframes osi-menu-overlay-close-circle {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}

@keyframes osi-menu-overlay-open-circle {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.osi-menu-container {
  position: absolute;
  overflow: hidden;
  overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  left: 0;
  top: 90px;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 140px);
  // -webkit-overflow-scrolling: touch;
}

.osi-menu-container .container {
  margin: auto;
}


.osi-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: osi-menu-counter;
}

.osi-menu ul.sub-menu {
  position: relative;
  overflow: hidden;
  max-height: 0;
  transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu .opened>ul.sub-menu {
  max-height: 1000px;
  transition: all 1.2s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 1.2s cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu ul li {
  z-index: 6;
  margin: 20px 0;
  position: relative;
  font-size: 49px;
  font-weight: 600;
  line-height: 1;
  counter-increment: osi-menu-counter;
  opacity: 0;
  transform: translateY(3px);
  -webkit-transform: translateY(3px);
  transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.6s cubic-bezier(0.3, 0, 0.3, 1);
  transform-origin: top left;
}

.osi-menu ul ul li {
  font-size: 0.6em;
}

.osi-menu ul li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
}

.osi-menu ul li:nth-child(3) {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}

.osi-menu ul li:nth-child(4) {
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
}

.osi-menu ul li:nth-child(5) {
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
}

.osi-menu ul li:nth-child(6) {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
}

.osi-menu ul li:nth-child(7) {
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
}

.osi-menu ul li:nth-child(8) {
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
}

.osi-menu ul li:nth-child(9) {
  transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
}

.osi-menu ul li:nth-child(10) {
  transition-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
}

.osi-menu-popup.menu--ready .osi-menu ul li {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}









.container.osimenucontainer {
  margin-left: 5em
}





.osi-menu ul li .icon {
  margin-left: 15px;
  position: relative;
  top: 30px;
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.4em;
  color: #aeaeae;
  cursor: pointer;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.3, 0, 0.3, 1);
}

.osi-menu ul li.opened>.icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.osi-header-default .osi-menu {
  text-align: left;
}

.osi-menu ul li.current-menu-item>a:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 1024px) {
  .osi-header-default .osi-menu ul li {
    font-size: 48px;
  }

  .osi-header-default .osi-menu ul ul li {
    font-size: 30px;
  }

  .osi-header-default .osi-menu ul ul ul li {
    font-size: 22px;
  }

  .osi-header-default .osi-menu ul li .icon {
    margin-top: 8px;
    top: 0;
  }

  .osi-header-default .osi-menu ul li li .icon,
  .osi-menu ul li li .icon {
    font-size: 20px;
    margin-top: -2px;
  }

  .osi-menu ul.sub-menu ul.sub-menu {
    background: rgba(255, 255, 255, 0.1);
    padding-left: 30px;
    padding-right: 30px;
  }

  .osi-menu ul.sub-menu .opened ul.sub-menu {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {

  .header--builder a.osi-head-btn,
  .header--builder .osi-head-btn {
    font-size: 0;
  }

  .osi-menu {
    padding-left: 40px;
  }

  .osi-menu ul li {
    margin: 20px 0;
    font-size: 38px;
  }

  .osi-menu ul li .icon {
    margin-left: 8px;
    margin-top: 4px;
    top: 0;
  }

  .osi-header-default .osi-menu ul li {
    font-size: 28px;
  }

  .osi-header-default .osi-menu ul ul li {
    font-size: 18px;
  }

  .osi-header-default .osi-menu ul ul ul li {
    font-size: 16px;
  }

  .osi-header-default .osi-menu ul li .icon {
    margin-top: -2px;
  }

  .osi-header-default .osi-menu ul li li .icon,
  .osi-menu ul li li .icon {
    font-size: 14px;
    margin-top: -2px;
    height: 20px;
    line-height: 20px;
  }

  .osi-header-default>.container>.row>* {
    max-width: 50%;
    width: 50%;
  }

  .osi-menu>ul>li>a {
    color: #fff;
  }

  .osi-menu ul.sub-menu .opened ul.sub-menu {
    margin-top: 5px;
  }
}

.osi-menu-horizontal ul li {
  position: relative;
  display: inline-block;
  margin: 0 15px;

  a {
    display: inline-block;
    color: $text-color; // Normal metin rengi
    position: relative;
    text-decoration: none;
    transition: color 0.4s cubic-bezier(0.3, 0, 0.3, 1);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: $accent-color; // Alt çizgi rengi
      transition: width 0.4s cubic-bezier(0.3, 0, 0.3, 1);
    }

    &:hover {
      color: #fff; // Hoverda metin rengi

      &::after {
        width: 100%;
      }
    }
  }
}








.osi-menu-popup.menu--ready .osi-menu ul li a {
  padding: 0.1em 0;
  color: $border-primary-color;
  position: relative;
  // letter-spacing: 1px;
  text-decoration: none;
}

.osi-menu-popup.menu--ready .osi-menu ul li a:before,
.osi-menu-popup.menu--ready .osi-menu ul li a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.osi-menu-popup.menu--ready .osi-menu ul li a:before{
  bottom: 0;
  display: block;
  height: 3px;
  width: 0%;
  content: "";
  background-color: $accent-color;
}

.osi-menu-popup.menu--ready .osi-menu ul li a:after {
  left: 0;
  top: 0;
  padding: 0.2em 0;
  position: absolute;
  content: attr(data-hover);
  color: #ffffff;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
}


.osi-menu-popup.menu--ready .osi-menu ul li a:hover:before,
.osi-menu-popup.menu--ready .osi-menu ul li .current a:before {
  opacity: 1;
  width: 100%;
}



.osi-menu-popup.menu--ready .osi-menu ul li a:hover:after,
.osi-menu-popup.menu--ready .osi-menu ul li .current a:after {
  max-width: 100%;
}


.dropdown-link.active a{
  padding: 0.1em 0 !important;
  color: #ffffff !important;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
 position: relative;
}

.dropdown-link.active a:before {
  bottom: 0 !important;
  display: block !important;
  height: 3px !important;
  width: 100% !important;
  content: "" !important;
  background-color: $accent-color !important;
  position: absolute !important;
  transition: all 0.35s ease !important;
  -webkit-transition: all 0.35s ease !important;
  -moz-transition: all 0.35s ease !important;
  -ms-transition: all 0.35s ease !important;
  -o-transition: all 0.35s ease !important;
}


.dropdown-link {

  width: max-content !important;
  
 
}