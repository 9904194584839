.menu-icon {
    width: 30px;
    height: 24px;
    cursor: pointer;
    position: relative;
  }
  
  .menu-icon svg {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in-out;
  }
  
  .menu-icon-path {
    transition: all 0.3s ease;
  }


  @media (max-width: 991px) {
    .menu-icon {
      display: inline-block; /* Küçük ekranlarda göster */
    }
  }