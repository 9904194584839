.blog-page .title-main:hover .content {
  transform: translateY(-65px);
}
.blog-page .title-main:hover .content-two {
  transform: translateY(-105px);
}

.content-two {
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  background-color: #1f1f1f;
}

.blog-page .title-main:hover .content-three {
  transform: translateY(-97px);
}
.content-three {
  position: relative;
}

.blog-page .title-main:hover .content-three:before {
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
  height: 63px;
  width: 100%;
  background: transparent;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  box-shadow: 0 20px 0 0#1f1f1f;
}
.content-three {
  cursor: pointer;
  transition: 0.5s;
  background-color:#1f1f1f;
}

.blog-page .title-main .answer-main {
  transform: translateY(0px);
  transition: 0.5s;
  opacity: 0;
  /* display: none; */
  height: 0vh;
}

.blog-page .title-main:hover .answer-main {
  transform: translateY(-99px);
  opacity: 1;
  /* display: block; */
  /* height: auto; */
}
.blog-page .cardSlider {
  overflow: hidden;
}
.blog-page .card-body {
  border-radius: 10px;
  padding: 0;
  /* height: 65vh; */
}
.blog-page  a{
  color: white !important;
  text-decoration: none !important;
}
@media screen and (max-width: 1200px) {
  .blog-page .title-main:hover .content,
  .blog-page .title-main:hover .content-two,
  .blog-page .title-main:hover .content-three,
  .blog-page .title-main:hover .answer-main {
    transform: none;
    opacity: 1;
    display: block;
  }

  .blog-page .title-main .content-three:before {
    content: none;
  }
  .blog-page .title-main .answer-main {
    transform: none;
  
    opacity: 1;
    display: block;
  }
  
  .blog-page .title-main:hover .answer-main {
    transform: none
 
  
  }
  .blog-page .card-body {

    height: 100%;
  }
}