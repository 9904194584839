.bg-services {
  background-image: url(../../assets/gallery/background_img/hizmetlerimiz_bg.png);
  background-size: cover;
}
.bg-services .banner_text{
  background-color: transparent;
}
.services-img-five{
  width: 100%;
}
.services-img-main{
    width: fit-content;
}
@media screen  and (max-width: 767px) {
  .services-img-main{
    top: 0%;
    right: 16%;
    transform: translate(50%, -50%);
    position: absolute;
    width: 25%;
}
.services-img-five{
  top: 0%;
  right: 16%;
  transform: translate(50%, -50%);
  position: absolute;
  width: 25%;
}
}