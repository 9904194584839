// Flexbox Centering
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // Media Query
  @mixin respond-to($breakpoint) {
    @if $breakpoint == small {
      @media (max-width: 600px) { @content; }
    } @else if $breakpoint == medium {
      @media (max-width: 900px) { @content; }
    } @else if $breakpoint == large {
      @media (max-width: 1200px) { @content; }
    }
  }
  
// Border Mixin
@mixin border($width: $border-width, $style: $border-style, $color: $border-color) {
  border: $width $style $color;
}

// Border Radius Mixin
@mixin border-radius($radius: $border-radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

  
  // Box Shadow
  @mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
            box-shadow: $shadow;
  }
  
  // Transition
  @mixin transition($property, $duration, $timing-function: ease) {
    -webkit-transition: $property $duration $timing-function;
       -moz-transition: $property $duration $timing-function;
        -ms-transition: $property $duration $timing-function;
            transition: $property $duration $timing-function;
  }
  
  // Text Truncate
  @mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  // Clearfix
  @mixin clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  
  // Visually Hidden
  @mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  // Responsive Font Size
  @mixin responsive-font($small, $large) {
    font-size: $small;
  
    @include respond-to(medium) {
      font-size: $large;
    }
  }

  @mixin bg-blur{
    position: relative;
    z-index: 2;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1); /* Şeffaf beyaz arka plan */
    -webkit-backdrop-filter: blur(10px); /* Safari ve diğer WebKit tarayıcıları için */
    // backdrop-filter: blur(10px); /* Diğer modern tarayıcılar için */
    border-radius: 10px;
    box-shadow: none;
  }
  
