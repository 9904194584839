.parallax {
  min-height: 500px; 
  background-attachment: fixed;
  /* background-position: start; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blog-details-page span{
  font-size: 10px;
}
.blog-details-page{
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: none;
}
.blog-details-page-main .banner_text{
 
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);

}
.blog-details-page-main .banner_text{
  margin-top: 5vh;
}